// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import styles from './PlatformFeed.module.scss';

const PlatformItem = ({ index, node }) => (
  <Link className={styles['feed__item']} to={node.fields.slug}>
    <div className={styles['feed__item-content']}>
      <h2 className={styles['feed__item-title']}>
        <Link className={styles['feed__item-title-link']} to={node.fields.slug}>
          #{index}. {node.frontmatter.title}
        </Link>
      </h2>
      <span className={styles['feed__item-readmore']}>Read</span>
    </div>
    <div className={styles['feed__item-content']}>
      <img
        src={node.frontmatter.image}
        alt={`${node.frontmatter.title}: Article Cover Image`}
      />
    </div>
  </Link>
);

// TODO
type Props = {
  headers: any,
  platform: any,
};

const Feed = ({ headers, platform }: Props) => (
  <div className={styles['feed']}>
    <h1 className={styles['feed__title']}>My Platform</h1>
    {headers.nodes.map((header, i) => (
      <>
        <h2 className={styles['feed__header-title']}>
          {header.frontmatter.title}
        </h2>
        <div className={styles['feed__header-image-container']}>
          <div className={styles['feed__header-image-container-img']}>
            <img
              src={header.frontmatter.image}
              alt={`${header.frontmatter.title}: Header Cover Image`}
            />
          </div>
          <div
            className={styles['feed__header-image-container-text']}
            dangerouslySetInnerHTML={{ __html: header.html }}
          />
        </div>
        {platform.group
          .find(({ groupName }) => groupName === header.frontmatter.group)
          .nodes.map((node, index) => (
            <PlatformItem index={index + 1} node={node} />
          ))}
        {i !== headers.nodes.length - 1 && <hr />}
      </>
    ))}
  </div>
);

export default Feed;

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Feed from '../components/PlatformFeed';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';

const AllPlatforms = ({ data }) => {
  const { subtitle: siteSubtitle } = useSiteMetadata();
  const { headers, platform } = data;
  console.log(headers, platform);

  return (
    <Layout title="My Platform" description={siteSubtitle}>
      <Sidebar isBlog />
      <Page>
        <Feed headers={headers} platform={platform} />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query PlatformTemplate {
    headers: allMarkdownRemark(filter: {frontmatter: {template: {eq: "platform-header"}}}, sort: {fields: frontmatter___group, order: DESC}) {
      nodes {
        html
        fields {
          slug
          categorySlug
        }
        frontmatter {
          image
          title
          group
        }
      }
    }
    platform: allMarkdownRemark(filter: {frontmatter: {template: {eq: "platform"}}}) {
      group(field: frontmatter___group) {
        groupName: fieldValue 
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            image
          }
        }
      }
    }
  }
`;

export default AllPlatforms;
